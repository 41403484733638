/**
 * This file does processing on the environment variables to fix the lack of environment variable per domain
 *
 * @link https://vercel.com/docs/concepts/projects/environment-variables#system-environment-variables
 */

const STAGING_BRANCH_NAME = "staging-talentticker";
const PROD_BRANCH_NAME = "master";

const GIT_BRANCH = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF;

const isProd =
  GIT_BRANCH === PROD_BRANCH_NAME ||
  process.env.NEXT_PUBLIC_VERCEL_ENV === "production";

const isStaging = GIT_BRANCH === STAGING_BRANCH_NAME;

/**
 * Dev is anything which isn't staging / production
 */
export const isDev = !isStaging && !isProd;

/**
 * Actual dev.talentticker.ai domain needs to be handled seperately
 */
const DEV_BRANCH_NAME = "dev";
const isDevBranch =
  process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === DEV_BRANCH_NAME;

export const SENTRY_DSN =
  process.env.SENTRY_DSN ||
  process.env.NEXT_PUBLIC_SENTRY_DSN ||
  "https://7003322dd826412b81b5a98494a26648@o195018.ingest.sentry.io/1299830";
export const SENTRY_RELEASE =
  process.env.RELEASE ||
  process.env.NEXT_PUBLIC_RELEASE ||
  process.env.VERCEL_GIT_COMMIT_SHA ||
  process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA ||
  "dev";
export const SENTRY_ENVIRONMENT = isDev
  ? "dev"
  : isStaging
  ? "staging"
  : "prod";

export const ENV = isDev
  ? process.env.NEXT_PUBLIC_DEV_ENV
  : isStaging
  ? process.env.NEXT_PUBLIC_STAGING_ENV
  : process.env.NEXT_PUBLIC_ENV;

// This allows us to overwrite the backend url in dev
const devApiUrl =
  process.env.NEXT_PUBLIC_DEV_API_BASE_URL || "dev-api.talentticker.ai";
export const API_BASE_URL = isDev
  ? devApiUrl
  : isStaging
  ? process.env.NEXT_PUBLIC_STAGING_API_BASE_URL
  : process.env.NEXT_PUBLIC_API_BASE_URL;

//TODO: Update the API Env Var inline with the package

export const WS = isDev
  ? process.env.NEXT_PUBLIC_DEV_WS
  : isStaging
  ? process.env.NEXT_PUBLIC_STAGING_WS
  : process.env.NEXT_PUBLIC_WS;

export const AUTH0_DOMAIN = isDev
  ? process.env.NEXT_PUBLIC_DEV_AUTH0_DOMAIN
  : isStaging
  ? process.env.NEXT_PUBLIC_STAGING_AUTH0_DOMAIN
  : process.env.NEXT_PUBLIC_AUTH0_DOMAIN;

export const AUTH0_CLIENT_ID = isDev
  ? process.env.NEXT_PUBLIC_DEV_AUTH0_CLIENT_ID
  : isStaging
  ? process.env.NEXT_PUBLIC_STAGING_AUTH0_CLIENT_ID
  : process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID;

export const AUTH0_RESPONSE_TYPE = isDev
  ? process.env.NEXT_PUBLIC_DEV_AUTH0_RESPONSE_TYPE
  : isStaging
  ? process.env.NEXT_PUBLIC_STAGING_AUTH0_RESPONSE_TYPE
  : process.env.NEXT_PUBLIC_AUTH0_RESPONSE_TYPE;

export const AUTH0_SCOPE = isDev
  ? process.env.NEXT_PUBLIC_DEV_AUTH0_SCOPE
  : isStaging
  ? process.env.NEXT_PUBLIC_STAGING_AUTH0_SCOPE
  : process.env.NEXT_PUBLIC_AUTH0_SCOPE;

export const AUTH0_AUDIENCE = isDev
  ? process.env.NEXT_PUBLIC_DEV_AUTH0_AUDIENCE
  : isStaging
  ? process.env.NEXT_PUBLIC_STAGING_AUTH0_AUDIENCE
  : process.env.NEXT_PUBLIC_AUTH0_AUDIENCE;

const VERCEL_URL = process.env.NEXT_PUBLIC_VERCEL_URL;

/**
 * Url used to redirect to after login
 * Only staging/production have the NEXT_PUBLIC_AUTH0_CALLBACK_URL environment variable set in Vercel
 * as this needs to be dynamic for all other branches
 */
export const AUTH0_CALLBACK_URL = isDev
  ? VERCEL_URL
    ? `https://${VERCEL_URL}/callback`
    : isDevBranch
    ? `https://dev.talentticker.ai/callback`
    : `http://localhost:3000/callback`
  : `https://${process.env.NEXT_PUBLIC_AUTH0_CALLBACK_URL}/callback`;

export const MAPBOX_ACCESS_TOKEN = process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN;

export const CHARGEBEE_SITE = isDev
  ? process.env.NEXT_PUBLIC_DEV_CHARGEBEE_SITE
  : isStaging
  ? process.env.NEXT_PUBLIC_STAGING_CHARGEBEE_SITE
  : process.env.NEXT_PUBLIC_CHARGEBEE_SITE;

export const BULLHORN_AUTH_URL =
  // Keeping this incase we add more
  // isLocal() ?
  //   `http://localhost:3000/bhcallback` :
  //   isDev ?
  //     `https://dev.talentticker.ai/bhcallback` :
  //     isStaging ?
  //       `https://staging.talentticker.ai/bhcallback` :
  `https://www.talentticker.ai/bhcallback`;

export const NOTIFICATIONS_REFRESH_TIME =
  process.env.NEXT_PUBLIC_NOTIFICATIONS_REFRESH_TIME;

export const COUNTDOWN_PAUSE_MS =
  process.env.NEXT_PUBLIC_FINAL_COUNTDOWN_PAUSE_MS;

export const LANDING_REFRESH_TIME =
  process.env.NEXT_PUBLIC_LANDING_REFRESH_TIME;

export const VINCERE_URL = isDev
  ? process.env.NEXT_PUBLIC_DEV_VINCERE_URL
  : isStaging
  ? process.env.NEXT_PUBLIC_STAGING_VINCERE_URL
  : process.env.NEXT_PUBLIC_VINCERE_URL;

export const CONFIG_CAT_KEY = isDev
  ? process.env.NEXT_PUBLIC_DEV_CONFIG_CAT_KEY
  : isStaging
  ? process.env.NEXT_PUBLIC_STAGING_CONFIG_CAT_KEY
  : process.env.NEXT_PUBLIC_CONFIG_CAT_KEY;

export const THEME =
  process.env.NEXT_PUBLIC_NEW_THEME || process.env.NEXT_PUBLIC_THEME;

export const LOGO_URL = process.env.NEXT_PUBLIC_LOGO_URL;

export const PRODUCT_NAME = process.env.NEXT_PUBLIC_PRODUCT_NAME;

export const NOINDEX = process.env.NEXT_PUBLIC_NOINDEX;
export const REQUEST_DEMO_URL = process.env.NEXT_PUBLIC_REQUEST_DEMO_URL;
export const GOOGLE_TAG_MANAGER_ID = process.env.NEXT_PUBLIC_GTM;

export const CONTENTFUL_SPACE = process.env.NEXT_PUBLIC_CONTENTFUL_SPACE;
export const CONTENTFUL_ACCESS_TOKEN =
  process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN;
export const CONTENTFUL_SIDE_MENU_ID =
  process.env.NEXT_PUBLIC_CONTENTFUL_SIDE_MENU_ID;
export const CONTENTFUL_FOOTER_SECTION_ID =
  process.env.NEXT_PUBLIC_CONTENTFUL_FOOTER_SECTION_ID;
export const CONTENTFUL_SEARCH_NEWS_PERMISSION_ERROR_ID =
  process.env.NEXT_PUBLIC_CONTENTFUL_SEARCH_NEWS_PERMISSION_ERROR_ID;
export const CONTENTFUL_SEARCH_VACANCIES_PERMISSION_ERROR_ID =
  process.env.NEXT_PUBLIC_CONTENTFUL_SEARCH_VACANCIES_PERMISSION_ERROR_ID;
export const CONTENTFUL_SIGNUP_MARKETING_ID =
  process.env.NEXT_PUBLIC_CONTENTFUL_SIGNUP_MARKETING_ID;
export const CONTENTFUL_JOBSEEKERS_ID =
  process.env.NEXT_PUBLIC_CONTENTFUL_JOBSEEKERS_ID;
export const CONTENTFUL_PREVIEW_TOKEN =
  process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_TOKEN;
export const CONTENTFUL_UPDATE_CONTACTS_TAGLINE =
  process.env.NEXT_PUBLIC_CONTENTFUL_UPDATE_CONTACTS_TAG_LINE;

/**
 * Book Demo Modal
 */

export const BOOK_DEMO_MODAL_ID =
  process.env.NEXT_PUBLIC_CONTENTFUL_BOOK_DEMO_MODAL_ID;
export const BOOK_DEMO_MODAL_LIST_ID =
  process.env.NEXT_PUBLIC_CONTENTFUL_BOOK_DEMO_MODAL_LIST_ID;
export const BOOK_DEMO_MODAL_SUCCESS_MESSAGE_ID =
  process.env.NEXT_PUBLIC_CONTENTFUL_BOOK_DEMO_MODAL_SUCCESS_MESSAGE_ID;
export const BOOK_DEMO_MODAL_FINE_PRINT_ID =
  process.env.NEXT_PUBLIC_CONTENTFUL_BOOK_DEMO_FINE_PRINT_ID;

/**
 * Talent Sourcing
 */

export const SOURCING_MIN_LEAVING_SCORE =
  process.env.NEXT_PUBLIC_SOURCING_MIN_LEAVING_SCORE;

export const SOURCING_MAX_REMAINING_SCORE =
  process.env.NEXT_PUBLIC_SOURCING_MAX_REMAINING_SCORE;

/* GSUITE */
export const GOOGLE_CLIENT_ID =
  isDev || isStaging
    ? process.env.NEXT_PUBLIC_DEV_GSUITE_INTEGRATION_CLIENT_ID
    : process.env.NEXT_PUBLIC_GSUITE_INTEGRATION_CLIENT_ID;
export const GOOGLE_CLIENT_SECRET =
  isDev || isStaging
    ? process.env.DEV_GSUITE_INTEGRATION_CLIENT_SECRET
    : process.env.GSUITE_INTEGRATION_CLIENT_SECRET; // Only available in the pages/api files
export const GOOGLE_SCOPES = ["https://www.googleapis.com/auth/gmail.send"];
export const GOOGLE_COMBINED_SCOPES = GOOGLE_SCOPES.join(",");

/**
 * Froala Editor
 */

export const FROALA_KEY = process.env.NEXT_PUBLIC_FROALA_KEY;
export const FROALA_ATTACHMENT_ENDPOINT = `https://${API_BASE_URL}/v1-email-integration/attachments`;

export const WEB_STORE_ID = process.env.NEXT_PUBLIC_CHROME_WEBSTORE_ID;
export const WEB_STORE_URL = `https://chrome.google.com/webstore/detail/talent-ticker/${WEB_STORE_ID}`;
